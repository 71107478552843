/* .bg-blackich {
  background-color: rgb(12, 12, 12);
}

.media,
.links,
.desc {
  padding: 2rem;
}

.links h6 {
  font-size: 1.4rem;
}

.links ul {
  padding-left: 0;
}

.links ul li {
  opacity: 0.6;
  font-weight: 300;
}

.desc-link {
  color: #0f0;
  text-decoration: none;
}

.desc ul {
  padding-left: 0;
  list-style: none;
}

.desc ul li::before {
  content: "\2022";
  color: #fff;
  margin-right: 0.3rem;
  font-size: 0.5rem;
}

.cert-img {
  height: 6rem;
}

.round-t {
  border-radius: 2rem 2rem 0 0;
}

.round-b {
  border-radius: 0 0 2rem 2rem;
}

.social-img img:hover {
  cursor: pointer;
} */

:root {
  --main-color: #ff8965;
}

/* shop now part */
.shop-now {
  background-image: url("../assets/footer-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(100px) !important;
}
.kontakt input[type="text"] , .kontakt input[type="email"] , .kontakt textarea
{
    background: rgba(63, 56, 56, 0.74);
}
.kontakt input[type="text"]:focus, .kontakt input[type="email"]:focus
{
    background: white;
}
textarea { resize: none; }

@media (min-width: 768px) {
  .f-links h6 {
    font-size: 1rem;
  }

  .f-links li {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .f-links h6 {
    font-size: 0.9rem;
  }

  .f-links li {
    font-size: 0.7rem;
  }
}

.text-fx-2{
  box-shadow: inset 0 0 0 0 #54b3d6;
  color: #fff;
  padding: 0 .25rem;
  margin: 0 -.25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}


.text-fx-2:hover {
  color: #fff;
  box-shadow: inset 200px 0 0 0 #ff7155;;
  border-radius: 10px;
}


ul li {
  margin: 0;
  list-style-type: none;
}
ul {
    padding: 0;
}
.the-arrow {
  width: 1px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #bdbdbd;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #bdbdbd;
}
.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 1px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 8px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  transform: rotate(40deg);
}
.the-arrow.-right > .shaft:after {
  transform: rotate(-40deg);
}
.the-arrow > .shaft {
  background-color: #bdbdbd;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  transform-origin: bottom right;
}
.animated-arrow {
  display: inline-block;
  color: #fff;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
}
.animated-arrow:hover {
  color: #bdbdbd;
}
.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #bdbdbd;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before,
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 8px;
  transition-delay: 0.1s;
  background-color: #bdbdbd;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}
.animated-arrow:hover > .main1 {
  transform: translateX(17px);
  transform: translateX(80px);
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:before,
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .main1 {
  display: flex;
  align-items: center;
  transition: all 0.2s;
  padding: 1rem 2rem;
  background: none;
  border-radius: 1rem;
}
.animated-arrow > .main1 > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .main1 > .the-arrow {
  position: relative;
}

@media (max-width: 425px) {
  .foot-btn {
    width: 75%;
    font-size: 0.7rem;
    padding: 4% 2%;
  }
}

@media (min-width: 425px) {
  .foot-btn {
  font-size: larger;
  padding: 0.5rem 3rem;
  }
}

.foot-btn {
  color: #fff;
  background-color: var(--main-color) !important;
}

.foot-btn:hover {
  opacity: 0.9;
  color: #fff;
}