.product-p img {
    max-width: 5rem;
    object-fit: scale-down;
}

.products-p {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;
    margin: 2rem 0;
}

.product-p {
    margin: 1rem 2rem;
}

.color-orange {
    color: #ff8965;
}

.pay-cont {
    padding: 0 15%;
}

.order-det {
    max-height: 25rem;
}

.order-cont {
    overflow-x: hidden;
}

.paypal {
    padding-top: 2rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (min-width: 768px) {
  .border-- {
  border-right: 2px solid #fff ; 
}
}
.visible div div div button{
  width: 500px !important;
}
.paypal-button-card .paypal-credit-logo {
  display: none;
}